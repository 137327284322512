import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { TechnologiesComponent } from './pages/technologies/technologies.component';
import { GcpComponent } from './pages/technologies/gcp/gcp.component';
import { AwsComponent } from './pages/technologies/aws/aws.component';
import { AzureComponent } from './pages/technologies/azure/azure.component';
import { VirtualizationComponent } from './pages/technologies/virtualization/virtualization.component';
import { CloudNativeComponent } from './pages/technologies/cloud-native/cloud-native.component';
import { DevOpsComponent } from './pages/technologies/dev-ops/dev-ops.component';
import { ProductsComponent } from './pages/products/products.component';
import { HealthComponent } from './pages/products/health/health.component';
import { LearningComponent } from './pages/products/learning/learning.component';
import { ServicesComponent } from './pages/services/services.component';
import { AboutComponent } from './pages/about/about.component';


const routes: Routes = [
{ path: '', component: MainComponent },

{ path: 'tech', component: TechnologiesComponent },
{ path: 'tech/gcp', component: GcpComponent },
{ path: 'tech/aws', component: AwsComponent },
{ path: 'tech/azure', component: AzureComponent },
{ path: 'tech/fundamentals/virtual', component: VirtualizationComponent },
{ path: 'tech/fundamentals/cloud', component: CloudNativeComponent },
{ path: 'tech/fundamentals/devops', component: DevOpsComponent },

{ path: 'products', component: ProductsComponent },
{ path: 'products/teledicom', component: HealthComponent },
{ path: 'products/learn', component: LearningComponent },

{ path: 'services', component: ServicesComponent },
{ path: 'about', component: AboutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
