import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { registerLocaleData } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';

import { MainComponent } from './pages/main/main.component';
import { HeaderComponent } from './componets/header/header.component';
import { TechnologiesComponent } from './pages/technologies/technologies.component';
import { ServicesComponent } from './pages/services/services.component';
import { AboutComponent } from './pages/about/about.component';
import { MainMenuComponent } from './componets/main-menu/main-menu.component';
import { LangPickerComponent } from './componets/lang-picker/lang-picker.component';
import { FooterComponent } from './componets/footer/footer.component';
import { Section2Component } from './componets/section2/section2.component';
import { HealthComponent } from './pages/products/health/health.component';
import { ProductsComponent } from './pages/products/products.component';
import { LearningComponent } from './pages/products/learning/learning.component';
import { GcpComponent } from './pages/technologies/gcp/gcp.component';
import { AwsComponent } from './pages/technologies/aws/aws.component';
import { AzureComponent } from './pages/technologies/azure/azure.component';
import { VirtualizationComponent } from './pages/technologies/virtualization/virtualization.component';
import { CloudNativeComponent } from './pages/technologies/cloud-native/cloud-native.component';
import { DevOpsComponent } from './pages/technologies/dev-ops/dev-ops.component';

import localePl from '@angular/common/locales/pl';
registerLocaleData(localePl, 'pl');

@NgModule({
	declarations: [
		AppComponent,
		MainComponent,
		HeaderComponent,
		TechnologiesComponent,
		ServicesComponent,
		AboutComponent,
		MainMenuComponent,
		LangPickerComponent,
		FooterComponent,
		Section2Component,
		HealthComponent,
		ProductsComponent,
		LearningComponent,
		GcpComponent,
		AwsComponent,
		AzureComponent,
		VirtualizationComponent,
		CloudNativeComponent,
		DevOpsComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		MatCardModule,
		MatButtonModule,
		MatMenuModule,
		MatInputModule,
		MatSnackBarModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MatGridListModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
