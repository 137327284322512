<div class="header-container">
  <div class="accent-main"></div>
  <div class="page-name"  i18n="@@pageTitleLearn">E-LEARNING</div>
</div>


<div class="column-container col-2">
  <div>
    <div class="col-accent"></div>
    <div class="col-content" i18n="@@ELearning1Cnt">
      During the times of epidemic threats, E-Learning has become extraordinarily utilized by educational units. However many of them have little experience in remote learning, leaving students with slow and inefficient conferencing tools, not applicable for remote tutoring, deprived of security or Knowledge Base building.
      Volentus expertise and experience is also related to a rich suite of Volentus E-learning (VE) solutions. Beside common tools used for communication and knowledge exchange, we provide access to well organized learning materials dedicated for students of colleges and universities.
    </div>

    <div class="col-break"></div>
    <div class="col-content" i18n="@@ELearning2Cnt">
      In addition to access to teaching materials prepared by academic teachers, as well as recordings of all online meetings, platform users have access to modern tools for remote cooperation - streaming of current meetings, chats, group editing of documents. This allows you to acquire not only substantive knowledge, but also to prepare for future professional work using modern IT tools.
      Volentus has formed an alliance with MCHE (EU Malopolska Region Educational Cloud) initiative aimed at activating education at the interface between secondary schools and universities. As part of the MChE, students can take part in profiled classes conducted by selected academic centers remotely. This is possible thanks to the use of dedicated software (including a social networking site, streaming servers) as well as modern network infrastructure and multimedia classes (including audiovisual teleconference terminals, multimedia boards), which were equipped with teaching units participating in the project. At present, classes are being conducted in almost 30 different thematic areas. Volentus can help you to implement this solution and gain access to thousands of hours and terabytes of proven academic learning materials online, with a robust VE-learning engine you can use for home-tutoring and conducting lessons in a remote classroom - either deployed on a chosen Cloud or within MCHE server center.
    </div>

    <div class="col-break"></div>
    <div class="col-content" i18n="@@ELearning3Cnt">
      Our implementation experts can also help companies in efficient deployment and integration of other commercial suites such as Google G-Suite Classroom and MS Office 365 Teams focusing on e-Learning and knowledge exchange in the companies. We help to migrate from on-premise models to cloud based and build best-practices through dedicated training for employees.  Other required activities like preparing the IT department to migrate to a cloud based model considering all required activities like deployment, configuration and management are also supported.
    </div>

    <div class="col-break"></div>
    <div class="col-content" i18n="@@ELearning4Cnt">
      Our support team is ready to help and guide you through the process of using the VE-Learning Cloud.
    </div>
  </div>

  <div>
    <div class="useless dodad1"></div>
  </div>
</div>
