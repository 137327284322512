<div class="header-container">
  <div class="accent-main"></div>
  <div class="page-name"  i18n="@@pageTitleServiceAws">Amazon Web Services</div>
</div>


<div class="column-container col-2">
  <div>
    <div class="col-accent"></div>
    <div class="col-header-container-single">
      <div class="col-header" i18n="@@awsHdr">AWS</div>
    </div>
    <div class="col-content" i18n="@@awsCnt">
  		<p>
		Amazon Web Services (AWS) is one of the first known cloud providers with a long history serving a variety of services since 2006. It offers services around the globe in many countries organized with regions and availability zones. AWS currently offers about 212 services spanning computing, storage and networking, database and analytics, application services. It also provides rich deployment, management and developer toolset enhancing support for modern mobile and IoT business services.
		Regarding AWS IaaS, Networking, Compute and Storage resources are exposed with Elastic Compute Service (EC2). Users can define rich VPC and load balancing configurations specified in global DNS. Virtual EC2 servers can be automatically managed with Auto Scaling policies which are integrated with CloudWatch monitoring systems. Storage options include EBS volumes attached to Ec2 and can be enhanced with S3 object service. Considering PaaS there are Elastic Beanstalk, serverless Lambda, RDS databases, analytics and big data solutions.
		</p>

		<div class="slide1">
	  		<img src="/assets/aws_slide1.png" width="100%">
	  	</div>

		<p>AWS User has rich access to many tools enhancing administration and developer experience:</p>
		<ul>
			<li>Management Tools; CloudWatch (Monitoring and Logs), Cloud Formation for Resource Templates and deployment automation, CloudTrail</li>
			<li>Security &amp; Identity providing users management, RBAC access control, SSL certificates management and accounting </li>
			<li>Rich Application Services required by microservice such as SQS, SNS, SES messaging, API Gateway, Canary Releases. </li>
			<li>Managed SQL/NoSQL Databases with automatic provisioning, monitoring , backups. </li>
		</ul>

		<p>
			Our company can provide support in case IT systems be deployed on AWS or even enhance current deployment. If required we can support our customer in improving current solution, support re-design and development of current application to better fit the AWS offering.  We can support cloud native microservice application development based on Elastic BeanStalk, Container platform, API Gateways or other AWS middleware systems. Our experience with DevOps can be valuable to provide deployment automation tools with advanced approaches like A/B, Blue Green deployments to ensure service agility.
		</p>
    </div>
  </div>

  <div>
    <div class="useless dodad1"></div>
  </div>

</div>
