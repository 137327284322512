<div class="header-container">
  <div class="accent-main"></div>
  <div class="main-column">
    <div class="company-logo-big"></div>
    <div class="main-text-container" i18n="@@landigText">
	<div class="emphasis">We cut costs not corners</div>

Our mission is to help businesses move to the cloud in a secure way and make the most of it.
We have necessary experience and knowledge to help you redesign your architecture in the most cloud-native way or simply perform a lift and shift migration.
Having expertise in major three cloud providers we can advise on which one would be best for your organisation, migrate from one to another or run a hybrid environment which could include your on-prem installation.
You will be able to run your applications in a private cloud or expose it as a SaaS offering.
</div>
  </div>
</div>

<div class="column-container col-2">
	<div class="useless">
		<div class="servers"></div>
	</div>
	<div>
		<div class="col-accent"></div>
			<div class="col-header-container-single">
				<div class="col-header" i18n="@@mainCloudReadyHdr">Cloud readiness assessment, <br> architecture review, <br> cloud migration and development</div>
			</div>
		<div class="col-content" i18n="@@mainCloudReadyCnt">
		While working on customer assignments our company always endevours to act pro-actively in order to bring additional customer value.
		As part of the project, team heads to formalize customer needs to improve existing Cloud solutions or migrate to the new model and help with customer future success.
		Our company can cover everything in areas of investigation, architecture, requirements definition and development, as well as further governance.

		As a managed services provider we can also completely take from you the headache of managing your cloud infrastructure so that you can focus more on your business and customers.
		</div>

		<div i18n="@@mainCloudReadySlogan" class="emphasis content-emp">We can show you benefits of the cloud and get you there!</div>
	</div>
</div>

<div class="column-container col-4">
  <div>
	<div class="col-header-container">
		<div class="col-header" i18n="@@mainCloudMigHdr">Cloud</div>
		<div class="col-sub-header" i18n="@@mainCloudMigSubHdr">Migration</div>
	</div>
	<div class="col-img col-img1"></div>
	<div class="col-content" i18n="@@mainCloudMigCnt">
			Support existing on-premise solutions towards migration to cloud models based on comprehensive investigation and requirements definition. Support for a rich set of domains like B2B, Remote Communication, Health Care, eLearning solutions.
	</div>
	<a mat-button routerLink="/services" class="orange-btn"  i18n="@@readMore">Read more</a>
  </div>

  <div>
	<div class="col-header-container">
		<div class="col-header" i18n="@@mainEWorkHdr">Work</div>
		<div class="col-sub-header" i18n="@@mainEWorkSubHdr">Online</div>
	</div>
		<div class="col-img col-img2"></div>
	<div class="col-content" i18n="@@mainEWorkCnt">
			Extensive experience with top cloud office solutions like Google G-Suite and Office 365. Supporting in providing blueprints for proper workflows when using cloud office solutions for remote office and business partners collaboration.
	</div>
  </div>

  <div>
	<div class="col-header-container">
		<div class="col-header" i18n="@@mainELearnHdr">E-learning</div>
		<div class="col-sub-header" i18n="@@mainELearnSubHdr">Online</div>
	</div>
		<div class="col-img col-img3"></div>
	<div class="col-content" i18n="@@mainELearnCnt">
Cloud e-learning solutions based on a variety of existing systems like Google Classroom, Office Teams, Cisco Webex enriched with recorded e-learning content dedicated for colleges and universities. Supporting organization in creating and improving methodologies for efficient remote learning.
	</div>
		<a mat-button routerLink="/products/learn" class="orange-btn" i18n="@@readMore">Read more</a>
  </div>

  <div>
	<div class="col-header-container">
		<div class="col-header">Health</div>
		<div class="col-sub-header">Online</div>
	</div>
	<div class="col-img col-img4"></div>
	<div class="col-content" i18n="@@mainEHealthCnt">
			Offering advanced and unique solutions for tele-consultation services used by domain experts in areas of cardiology and pulmonology. Our solutions are used by top physicians experts to consult patient cases and provide quick diagnosis opinion to others.
	</div>
	<a mat-button routerLink="/products/teledicom" class="orange-btn"  i18n="@@readMore">Read more</a>

  </div>
</div>

<app-section2></app-section2>

<div class="column-container col-2">
	<div>
		<div class="col-content">
			<div class="useless cosmo"></div>

		</div>
	</div>
	<div>
		<div class="col-accent"></div>
		<div class="col-content">
		<div class="col-header" i18n="@@mainMethodologyHeader">Well proven methodology:</div>
		<div class="numbered-content">&nbsp;</div>

		<div class="numbered-header" i18n="@@mainMethodologyHdr1"><span class="numbered-counter">1.</span>Investigation</div>
		<div class="numbered-content" i18n="@@mainMethodologyCnt1"> As part of the investigation phase our team tries to anticipate customer needs, to improve existing Cloud solutions or migrate to the new model and help with future success. During the phase together with customers we are conducting current solution analysis considering cloud requirements for high availability, performance, security and cost efficiency. Having such elements well defined and agreed next step is to prepare requirements and architecture of the solution. </div>

		<div class="numbered-header" i18n="@@mainMethodologyHdr2"><span class="numbered-counter">2.</span>Requirements and Architecture</div>
		<div class="numbered-content" i18n="@@mainMethodologyCnt2">We are driving development work and assisting in requirements definition related to Cloud technology, services adaptation and new modules to be developed. We will help to understand the most efficient approach related to cloud stack technology offered by the provider such as IaaS, PaaS service models applicability. Finally new cloudified architecture of the solution will be proposed with detailed project planning and cost estimation. </div>

		<div class="numbered-header"i18n="@@mainMethodologyHdr3"><span class="numbered-counter">3.</span>Cloud Solution Development</div>
		<div class="numbered-content" i18n="@@mainMethodologyCnt3">Finally we are supporting customers in solution cloudification related to deployment automation and DevOps, security hardening and governance. If required we can support in developing proper software modules to integrate migrated solutions with legacy systems or even help to perform refactoring to new improved cloud environment architecture. Each project is finished by verification phase considering not only functional but also performance and security requirements. </div>


		</div>
	</div>
</div>
