<div class="header-container">
  <div class="accent-main"></div>
  <div class="page-name"  i18n="@@pageTitleService">SERVICES</div>
</div>


<div class="column-container col-2">
	<div>
		<div class="col-accent"></div>
			<div class="col-header-container-single">
				<div class="col-header" i18n="@@techCloudReadyHdr">Cloud readiness assessment <br> and architecture review</div>
			</div>
		<div class="col-content" i18n="@@techCloudReadyCnt">While working on customer assignments our company always try to act pro-active in order to bring additional customer value. As part of that team tries to anticipate customer needs, to improve existing Cloud solutions or migrate to the new model and help with your future success.  Foreseen areas are investigation, requirements definition, architecture and development for improvements of current solutions to be migrated to Cloud.</div>
	</div>

	<div>
		<div class="col-accent"></div>
			<div class="col-header-container-single">
				<div class="col-header" i18n="@@techCloudMigHdr">Cloud migration</div>
			</div>
		<div class="col-content" i18n="@@svcCloudMigCnt">
			 <div short> Projects related to Cloud migration are always complex projects which require joint teams working together
	          	on proposals, drafts, technical studies, and identify gaps and risks in technology. We help to define customer
	          	user stories to support project planning and resource estimations.
			</div>
	         <div long>
		          <p> We are driving development work and assisting in requirements definition related to
		            Cloud technology, services adaptation and new modules to be developed. We will help to understand the most
		            efficient approach related to cloud stack technology offered by the provider such as IaaS , PaaS service
		            models applicability. </p>

		          <p> Finally new cloudified architecture of the solution will be proposed with detailed project
		            planning and cost estimation.
		          </p>
	        </div>
		</div>
	</div>

	<div>
		<div class="col-accent"></div>
			<div class="col-header-container-single">
				<div class="col-header" i18n="@@svcSecurityHdr">Security audit <br> and recommendations</div>
			</div>
		<div class="col-content" i18n="@@svcSecurityCnt">
	        <div short> Security is important element of modern business software solutions, It requires proper understanding and
	          solid approach which can be ensured by top experts.
	          Though Cloud providers ensure many components required by modern software still security analysis is
	          crucial. </div>

	        <div long>
	            <p> In our approach we always treat security as a process with well-defined roles, responsibilities and
	              procedures which must have top management support and understanding.
	              We help to define strategies for risk management, analysis and mitigation across organizations. We can provide
	              service for compliance and audit on
	              timely bases to assure compliance with legal and regulatory requirements.</p>

	            <p> Finally, project security sign-off with recommendations and verification of architecture, design and
	              standard security controls will be prepared and discussed with management.
	            </p>
	        </div>
		</div>
	</div>

	<div>
		<div class="col-accent"></div>
			<div class="col-header-container-single">
				<div class="col-header" i18n="@@svcMgtCloudHdr">Managed Cloud Services</div>
			</div>
		<div class="col-content" i18n="@@svcMgtCloudCnt">
			   <p> Our company can support deployment of managed cloud service solutions in customer owned collocation
	          facilities.
	          Based on needs specific IT functions of managed cloud solutions can be deployed to provide infrastructure and
	          applications level support.
	          Managed cloud solutions include computing, storage, networks, and operating systems, and middleware and
	          application stacks that run on top of that infrastructure.
	          Cloudified middleware and applications are crucial to provide a cloud based environment to develop and operate
	          modern business applications in on-premise clouds.
         </p>

         <p> In case of Infrastructure managed clouds we can offer training and architecture guidance for system
              operations, performance monitoring and tuning,
             database and solutions administration including security and compliance, backups and recovery, and continuous
             availability.
          </p>

          <p> At the application level our expertise in areas of DevOps automation tools, application architecture,
	            development and deployment will bring great
	            value to customers. We can support our customers in challenges related to applications deployed in the cloud
	            such as agility, scaling, HA and security.
	            Beside we can provide training or integration adapters for well known SaaS solutions such Microsoft or Google
	            applications.
          </p>
		</div>
	</div>
	<div>
		<div class="col-accent"></div>
			<div class="col-header-container-single">
				<div class="col-header" i18n="@@svcMgtCloudHdr">Medical Imaging</div>
			</div>
		<div class="col-content" i18n="@@svcMgtCloudCnt">
			   <p> Having yearlong experience in TeleMedicine solutions and Healthcare support, 
				the Volentus team focuses on challenges in understanding patient health status described in medical images acquired 
				with modalities  CT, X-Ray, PET, Ultrasound, and MRI, which are essential to many diagnostic and treatment 
				processes in healthcare. 
         </p>
		 <p>
			Our experience in cloud processing as Google Cloud Partner and +15 years of implementing big data solutions, enable the processing of medical digital imaging artefacts in the widest recognized standard - DICOM. Thanks to collaboration with healthcare units, we developed capabilities to provide a scalable environment from which external services can ingest, manage, and analyse all healthcare data types:
			<ul>
				<li>Cataloguing files and extracting file-based metadata</li>
				<li>Selecting files based on metadata parameters via flexible SQL queries</li>
				<li>Generating and visualising data in reports</li>
			</ul>
			Thanks to the combination of community standards and open libraries, we can deliver data lakes exposed via a reliable, flexible method to store the (meta) data extracted from healthcare DICOM files. 
		 </div>
	</div>

</div>


