import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lang-picker',
  templateUrl: './lang-picker.component.html',
  styleUrls: ['./lang-picker.component.scss']
})
export class LangPickerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
