<div class="header-container">
  <div class="accent-main"></div>
  <div class="page-name" i18n="@@pageTitleServiceGcp">Google cloud platform</div>
</div>

<div class="column-container col-2">
  <div>
    <div class="col-accent"></div>
    <div class="col-header-container-single">
      <div class="col-header" i18n="@@gcpHdr">GCP</div>
    </div>
    <div class="col-content" i18n="@@gcpCnt">
      <p>
        GCP cloud market share is smaller than AWS or Azure because it didn't open up its services to the public
        until few years ago, whereas its two main rivals, AWS in particular, have enjoyed first mover advantage. Up
        until recently Google has been focusing hard on successfully closing the functionality gap to its competitors.
      </p>

      <div>
        <p>But why do companies choose Google over other providers?</p>
        <ul>
          <li>its reputation for running large scale services (Gmail, Maps, YouTube)</li>
          <li>Google has been also known for innovation. A few notable mentions are: MapReduce (Hadoop/Spark), BigTable
            (Hbase), Kubernetes (de facto standard now for containers orchestration) and TensorFlow (one of the most
            popular frameworks for machine learning)
          </li>
          <li>reliability and adhering SRE principles</li>
          <li>superior big data analytics and ML offering</li>
          <li>Google Kubernetes Engine is most popular cloud platform for running workloads on Kubernetes</li>
          <li>Security standards, regulatory compliance</li>
        </ul>
        <p> We are official Google Partner. Members of our team hold five
          <a href="https://cloud.google.com/certification">Google Cloud professional certifications </a>
        </p>
        <ul>
          <li>Cloud Architect</li>
          <li>Data Engineer</li>
          <li>Cloud Security Engineer</li>
          <li>Cloud Network Engineer</li>
          <li>Cloud Developer</li>
        </ul>
        <p>We have worked on some of the largest GCP installations in UK's financial sector and have helped a few
          companies to successfully migrate to G Suite</p>
      </div>
    </div>
  </div>

  <div>
    <div class="col-accent"></div>
    <div class="col-header-container-single">
      <div class="col-header" i18n="@@gsHdr">G-SUITE</div>
    </div>
    <div class="col-content" i18n="@@gsCnt">
      <p>
      G Suite gives companies access to Google's office collaboration tools: GMail, Docs, Sheets, Slides, Meet, Chat,
      and many others with enteprise features
      such as reliability and extra security at your organisation level. It has already helped many companies to stream
      line process, reduce amount of internal e-mails
      and administration as well as shift internal culture towards more collaboration.</p>
      <p>Top G-Suite features which our customers have liked so far are:</p>
      <ul>
        <li>Users and groups management</li>
        <li>Security features available via admin console: 2FA and Endpoint Verification</li>
        <li>Multiple people working on one document at the same time</li>
        <li>GMail client to manage company's e-mails</li>
        <li>Google Meet and Google Chat for both internal and external communication</li>
        <li>Greatly reducing data exfiltration risk</li>
        <li>Calendar and resource management</li>
      </ul>
    </div>
  </div>
</div>

