<div class="header-container">
  <div class="accent-main"></div>
  <div class="page-name"  i18n="@@pageTitleServiceVrt">Virtualization</div>
</div>

<div class="column-container col-2">
<div>
    <div class="col-accent"></div>
    <div class="col-header-container-single">
      <div class="col-header" i18n="@@virtHdr"></div>
    </div>
    <div class="col-content" i18n="@@virtCnt">
      <p>
        Virtualization is elementary technology used by cloud platforms and enables intelligent partitioning of hardware compute,
        storage and network resources. Virtual machines or containers are used to host virtualized workloads of client applications.
      </p>
      <p>
        Hardware and software advances introduce concepts of virtualization, which provides mechanisms for advanced management of
        resources e.g. devices, storage address spaces and OS instances providing resources consumption granularity, some degrees of isolation and flexibility.
      </p>

	  <div class="slide1">
	  	<img src="/assets/virt_slide1.png" width="100%">
	  </div>

      <p>
        Hardware Partitioning: Domains are a solution which involves hardware and firmware interfaces to divide a computer's hardware
        resources so they can host multiple OSes. They usually have some architectural limitations like a fixed number of OS instances,
        amount of resource granularity or sharing. This approach is mostly used on high-end servers from IBM, Oracle, HP vendors.
        These days many companies using the technology are evolving towards VM and Container technologies.
	   </p>
     <p>
        Virtual Machines (VM) are a technology that enables running operating system instances in domains that share a physical server resources.
        Such domains are isolated environments to be managed by hypervisor (a.k.a. Virtual Machine Monitor - VMM). Hypervisor performs emulation of some real
        hardware resources enabling it to run some number of Virtual Machines. Each VM has the illusion of owning hardware and is able to manage an arbitrary guest
        operating system. Hypervisors usually do not have fixed limits on the number of OS instances and enable specification of fine-grained resource controls settings.
        Examples of the technology are KVM, Xen, VMware, AWS EC2.
     </p>
     <p>
		    OS Container Virtualization: Another technology is Container virtualization, in which there is only one underlying operating system kernel, which the containers
        enhance by providing distinct borders offering increased isolation between groups of processes. Software that virtualizes an operating system environment enables it
        to run some number of container instances, which do not emulate any of the underlying hardware. Instead, the container or application communicates with the host OS to
        share resource usage, which then makes the appropriate calls to real hardware. Examples of the technology are Linux OpenVZ/LXC/LXD, Solaris Zones. Container virtualization is
        currently offered by each well known cloud provider and gains more attention when considering Cloud natve and containerized architectures.
      </p>
      <p>
        Many microservices based applications are utilizing Docker image containers as deployment units which provide easier release management and governance.
        Each cloud provider exposes virtualisation solutions through Infrastructure as a Service and equips users or developers with a rich toolset to easier provisioning of VM's or containers,
        storage and network resources. Such units can be automatically managed, scaled and mostly have built-in HA functionality
      </p>

      <p>
        Volentus can help each customer to choose proper virtualisation technology based on VM or Container based on security requirements, performance and application category. We can prepare designs for multi-virtualisation solutions utilising VM and containers at the same time managed by a unified framework for deployment automation and monitoring.
      </p>
    </div>
  </div>
</div>
