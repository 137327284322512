<div class="header-container">
  <div class="accent-main"></div>
  <div class="page-name"  i18n="@@pageTitleServiceDev">DevOps and Automation</div>
</div>

<div class="column-container col-2">
  <div>
    <div class="col-accent"></div>
    <div class="col-header-container-single">
      <div class="col-header" i18n="@@devHdr">DevOps and Automation</div>
    </div>
    <div class="col-content" i18n="@@devCnt">
      <p>
        DevOps refers to the process of collaborative work between the Development (Dev) and IT Operations (Ops).
        Its main focus is to provide fast flow of planned work (such as high deploy rates), increase reliability,
        stability and security of production environment. Simply speaking applying DevOps practices enables releasing software packages into
        production with best quality after extensive automated verification procedures.  DevOps is especially complementary to the Agile software
        development process, as it extends and completes the continuous integration and release process by ensuring the code is production ready
        and providing value to the customer.
      </p>

	 <div>
		<div class="col-accent"></div>
		 <div class="col-header-container-single">
	  		<div class="col-header">DevOps Lifecycle</div>
		 </div>
		<img src="/assets/devops.png" width="100%">
	 </div>

      <div class="col-accent"></div>
      <p>
        Introducing DevOps methodology requires in-depth knowledge for provisioning and deployment automation tools, continuous integration frameworks and test automation.
        Each cloud provider provides a certain set of tools supporting the process. Nevertheless, efficient DevOps implementation requires proper
        design and implementation phases where many development departments working on particular service solutions need to be injected into the release
        workflow with strict testing and production deployment.
      </p>
	  <p>
		Our employees are experienced with many deployment automations tools like Ansible, Terraform, AWS-Cloudformation, GCP-Deployment Manager,
        Azure Resource Manager. We have experience with Jenkins pipeline integrated with above automation tools or if required can integrate others.
        To ensure a best quality release process we can support in proper utilization of Canary releases, Blue-Green deployments combined with continuous
        monitoring tools to provide service readiness and availability.
      </p>
    </div>
  </div>

  <div style="position: relative;">
	<div class="dodad5 useless"></div>
  </div>
</div>
