import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gcp',
  templateUrl: './gcp.component.html',
  styleUrls: ['./gcp.component.scss']
})
export class GcpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
