<div class="header-container">
  <div class="accent-main"></div>
  <div class="page-name"  i18n="@@pageTitleTech">TECHNOLOGIES</div>
</div>

<div class="column-container col-4">
  <div>
    <div class="col-header-container">
      <div class="col-header" i18n="@@techHdrGCPHdr">GCP</div>
      <div class="col-sub-header" i18n="@@techHdrGCPSubHdr">and G-Suite</div>
    </div>
    <div class="col-img col-img1"><div class="col-img gcp-logo"></div></div>
    <div class="col-content" i18n="@@techHdrGCPCnt">
 GCP cloud market share is smaller than AWS or Azure because it didn't open up its services to the public until few years ago, whereas its two main rivals, AWS in particular,
 have enjoyed first mover advantage. Up until recently Google has been focusing hard on successfully closing the functionality gap to its competitors.
    </div>
    <a mat-button routerLink="/tech/gcp" class="orange-btn" i18n="@@readMore">Read more</a>
  </div>

  <div>
    <div class="col-header-container">
      <div class="col-header" i18n="@@techAWSHdr">AWS</div>
      <div class="col-sub-header" i18n="@@techAWSSubHdr">Amazon Web Services</div>
    </div>
    <div class="col-img col-img2" ><div class="col-img aws-logo"></div></div>
    <div class="col-content" i18n="@@techAWSCnt">
	Amazon Web Services (AWS) is one of the first known cloud providers with a long history serving a variety of services since 2006.
  AWS currently offers about 212 services spanning computing, storage and networking,
	database and analytics, application services. It also provides rich deployment, management and developer toolset enhancing support for modern mobile and IoT business services.
	</div>
    <a mat-button routerLink="/tech/aws" class="orange-btn" i18n="@@readMore">Read more</a>
  </div>

  <div>
    <div class="col-header-container">
      <div class="col-header" i18n="@@techAzHdr">Azure</div>
      <div class="col-sub-header" i18n="@@techAzSubHdr">and Office 365</div>
    </div>
    <div class="col-img col-img3"><div class="col-img azure-logo"></div></div>
    <div class="col-content" i18n="@@techAzCnt">
		Microsoft started offering cloud services much later than Amazon but since grew to position of major player in the market. Microsoft Azure offering spans across all components of modern scalable aplication.
		Storage, Scalable web application hosting, distributed databasees as well as integration with other microsoft's business offerings.
    </div>
    <a mat-button routerLink="/tech/azure"  class="orange-btn" i18n="@@readMore">Read more</a>
  </div>

  <div>
    <div class="col-header-container">
      <div class="col-header">CF</div>
      <div class="col-sub-header" i18n="@@techPCFHdr">Cloud foundry</div>
    </div>
    <div class="col-img col-img2" ><div class="col-img pcf-logo"></div></div>
    <div class="col-content" i18n="@@techPCFCnt">
		 Cloud Foundry seeks to provide unified api to all major vendors and provide abstraction layer allowing multi-cloud environments.
     	 Such setup can be beneficial for companies trying to optimize costs by moving service between providers or simply looking for additional level of redundancy.
    </div>

  </div>
</div>

<div class="section-header section2">
  <div class="accent-main"></div>
  <div class="section-title" i18n="@@techCloudFundHdr">Cloud Fundamentals</div>
</div>

<div class="column-container col-3">
  <div>
    <div class="col-header-container">
      <div class="col-header" i18n="@@techHdrVituHdr">Virtualization</div>
    </div>
    <div class="col-content" i18n="@@techHdrVituCnt">
      Virtualization is elementary technology used by cloud platforms and enables intelligent partitioning of hardware compute,
      storage and network resources. Virtual machines or containers are used to host virtualized workloads of client applications.
    </div>
    <a mat-button routerLink="/tech/fundamentals/virtual" class="orange-btn" i18n="@@readMore">Read more</a>
  </div>

  <div>
    <div class="col-header-container">
      <div class="col-header" i18n="@@techHdrCnsHdr">Cloud Native Solutions</div>
    </div>

    <div class="col-content" i18n="@@techHdrCnsCnt">
      Cloud-native application is designed and implemented to run on a PaaS to embrace horizontal elastic scaling, self HA control  and operational elasticity. Cloud Native solutions exploit cloud runtime offerings in the most efficient way.
    </div>
    <a mat-button routerLink="/tech/fundamentals/cloud" class="orange-btn" i18n="@@readMore">Read more</a>
  </div>

  <div>
    <div class="col-header-container">
      <div class="col-header" i18n="@@techDevHdr">DevOps and Automation</div>
    </div>

    <div class="col-content" i18n="@@techDevCnt">
      DevOps refers to the process of collaborative work between the Development (Dev) and IT Operations (Ops).
      Allows for fast flow of planned work (such as high deploy rates), increased reliability, stability and security of production environment.
    </div>
    <a mat-button routerLink="/tech/fundamentals/devops"  class="orange-btn" i18n="@@readMore">Read more</a>
  </div>
</div>
