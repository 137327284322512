<div class="header-container">
  <div class="accent-main"></div>
  <div class="page-name">ABOUT US</div>
</div>


<div class="column-container col-2">
	<div>
		<div class="col-accent"></div>
			<div class="col-header-container-single">
				<div class="col-header" i18n="@@aboutHdr">About US</div>
			</div>
		<div class="col-content" i18n="@@aboutCnt">
			<p>
			Prior to founding Volentus we have worked for and with software houses,
			tier 1 investment banks and some of the largest IT companies.
			All our founders have more than 15 years of experience, some even more than 20 and PhD in computer science.
			We have worked in Poland, UK and Switzerland and know specifics of each of these markets.
			</p>
		</div>
		<div>
			<div class="col-accent"></div>
				<div class="col-header-container-single">
					<div class="col-header" i18n="@@aboutEmailHdr">You can contact us by Email at:</div>
				</div>
			<div class="col-content" i18n="@@aboutEmailCnt">
	    		<a class="contact" i18n-href href="mailto:info@volentus.com">info@volentus.com</a>
	  		</div>
		</div>
	</div>

	<div class="useless">
		<div class="mobile"></div>
	</div>

</div>
