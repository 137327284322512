<div class="header-container">
  <div class="accent-main"></div>
  <div class="main-column">
	<div class="section-caption" i18n="@@section2Hdr">HOW IT WORKS?</div>
	<div class="main-text-container" i18n="@@section2HdrText">
		Cloud platforms expose elastic environments to host specific workloads and be expanded on demand to handle defined performance criteria. 
		Based on the applied business model and strategy there can be used three models: Public, Private and Hybrid clouds. 
		Cloud computing also defines three service models which are Infrastructure as a Service (IaaS), Platform as a Service (PaaS) and SaaS (Software as a Service). 
		Built on one another defines what is the responsibility of the provider and customer. 
		The infrastructure and application layers are programmable where each operation related to IaaS and PaaS is exposed via a well-known API defined by the cloud provider. 
		Such software defined infrastructure enables full automation of deployment and enables realisation of DevOps approach when developing and operating software solutions in the Cloud. 
		Funcional, secure and cost effective applicability of the particular cloud provider consists of many activities related to investigation, requirements and architecture definition and finally development.
	</div>
	<div class="section-caption" i18n="@@section2HdrFooter">EASY!</div> 
 </div>
</div>