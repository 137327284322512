<div class="main-menu-container">
  <a mat-button routerLink="/" i18n="@@menuHome">Home</a>
  <a mat-button routerLinkActive="active-menu-item" routerLink="/services"  i18n="@@menuService">Services</a>
  <a mat-button routerLinkActive="active-menu-item" routerLink="/tech"  i18n="@@menuTech">Technologies</a>
  <a mat-button routerLinkActive="active-menu-item" [matMenuTriggerFor]="prodMenu" i18n="@@menuProd">Products</a>
  <a mat-button routerLinkActive="active-menu-item" routerLink="/about" i18n="@@menuAbout">About Us</a>
</div>

<div class="main-menu-mobile">
  <a mat-button routerLinkActive="active-menu-item" [matMenuTriggerFor]="mobileMenu"><span class="hamburger">≡</span></a>
</div>

<mat-menu #prodMenu="matMenu">
  <button mat-button  routerLink="/products/learn"  i18n="@@menuProdLearn">VE-Learning</button>
  <button mat-button  routerLink="/products/teledicom"  i18n="@@menuProdHeath">TeleDICOM</button>
</mat-menu>

<mat-menu #mobileMenu="matMenu">
  <a mat-menu-item  routerLink="/" i18n="@@menuHome">Home</a>
  <a routerLinkActive="active-menu-item" mat-menu-item routerLink="/services"  i18n="@@menuService">Services</a>
  <a routerLinkActive="active-menu-item" mat-menu-item routerLink="/tech"  i18n="@@menuTech">Technologies</a>
  <a routerLinkActive="active-menu-item" mat-menu-item [matMenuTriggerFor]="prodMenu" i18n="@@menuProd" xPosition="after" yPosition="after">Products</a>
  <a routerLinkActive="active-menu-item" mat-menu-item routerLink="/about" i18n="@@menuAbout">About Us</a>
</mat-menu>
