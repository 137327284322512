<div class="header-container">
  <div class="accent-main"></div>
  <div class="page-name"  i18n="@@pageTitleHealth">TELEDICOM</div>
</div>

<div class="column-container col-2">

  <div>
    <div class="col-accent"></div>
    <div class="col-content" i18n="@@Telemedicine0Cnt">
	  <div class="emphasis content-emp">Telemedicine for Medical Specialists </div>
In the era of virus and epidemic threats, Volentus provides and integrates TeleDICOM - a cloud-based eHealth service used by physicians who can provide remote treatment and consulting of patient cases to provide quick diagnosis opinion to others.

TeleDICOM provides an environment for remote and shared desktops with imaging DICOM data files for interactive consultations among physicians. Currently, it has been operated and updated for many years among +50 European hospitals and enabling several thousand consultations a year. The solution has been used within International scientific cooperation of medical experts in Rare Cardiovascular Diseases.
TeleDICOM can support the hospital and medical care not only during epidemics, but any healthcare domain including cardiology, pulmonology, dentistry etc. in normal operations including:
<ul>
	<li> Remote on-line and off-line consultations </li>
	<li> Student lectures and medical e-learning </li>
	<li> Knowledge Base and recordings for future learning </li>
	<li> Basis of cases for employment AI and Machine learning algorithms </li>
</ul>

The philosophy of conducting consultation of particular patient cases is based on simple blueprints with data acquisition and analysis which in case of remote methodology can be performed in different medical centers.

    </div>
  </div>

  <div>
    <div class="slide1"></div>
  </div>

  <div>
    <div class="col-content">
		<div class="slide2"></div>
    </div>
  </div>

  <div style="display: flex ; flex-direction: column; justify-content: center;">
    <div class="col-content" i18n="@@Telemedicine1Cnt" >
Remote interactive session consists of multiple participant roles like requesting physician, consultant(s) and observers (students or other physicians) who share the same secure workspace with common data (DICOM images, other graphical files) and view, and integrated voice and chat channels. Such work organization enables better utilization of expert knowledge especially valuable when discussing urgent and rare cases in case of pandemic or when access to high quality experts is limited in a particular medical center. Efficient dissemination of expert knowledge and best practices in imaging medicine enables rich experience and knowledge exchange.  Open training sessions for medical students, doctors in low-reference medical centers and medical conferences or workshops are much easier than before.
Apart from the scalable cloud version, the system can also be installed on internal hospital IT resources, serving individually a network of connected medical centers.
    </div>
  </div>
</div>

<div class="column-container col-1">

 <div>
    <div class="col-accent"></div>
    <div class="col-content" i18n="@@Telemedicine2Cnt">
Enabling TeleDICOM in your healthcare unit will enable your personnel to perform e-consultations in mundane as well as extraordinary circumstances such as pandemic to be able to come together and address a few basic problems that the group faces on a daily basis: medics, patients. It cannot be hidden that medical units are exposed to epidemiological threat. According to estimates, 17% of those infected are medical services, and disabling any doctor or team, regardless of specialization, is a huge blow in the possibility of treating patients, which contributes to uncountable losses in the society, and the local authorities force urgent response and shut down units, making additional charges to other units.
Most of those infected with Corona type viruses pass through mild or asymptomatic infection. However, due to the quarantine compulsion, doctors are cut off from the possibility of helping patients, and all their experience and the usefulness remains unused. In addition, due to the limited mobility, the requirement of social distance and a number of restrictions on direct contacts, telemedicine nowadays seems not just an IT improvement but also the only existing option.
Contact us for an assessment of your healthcare unit to connect to the TeleDICOM community!
    </div>
  </div>
</div>

<div class="column-container col-2">
  <div>
  	<img src="/assets/dicom_ss1.png" width="100%">
  </div>

  <div>
	<img src="/assets/dicom_ss2.png" width="100%">
  </div>
</div>










