<div class="header-container">
  <div class="accent-main"></div>
  <div class="page-name"  i18n="@@pageTitleServiceCn">Cloud Native Solutions</div>
</div>

<div class="column-container col-1">
  <div>
    <div class="col-accent"></div>
    <div class="col-header-container-single">
      <div class="col-header" i18n="@@cnHdr">Cloud Native Solutions</div>
    </div>
    <div class="col-content" i18n="@@cnCnt">
      <p>
        Cloud-native application is designed and implemented to run on a PaaS to embrace horizontal elastic scaling, self HA control  and operational elasticity. Cloud Native solutions exploit cloud runtime offerings in the most efficient way.
      </p>

      <p>
        When considering cloud environment advantages like support for built-in HA, scaling, automation and rich platform solutions (DB, LB,Monitoring, Storage) availability, community defined notion of Cloud Native Services which exploit cloud runtime offering in the most efficient way.
        A cloud-native service is an application that has been designed and implemented to run on a Platform-as-a-Service installation and to embrace horizontal elastic scaling, self HA control  and operational elasticity. Cloud native services require PaaS platforms which are essentially the next generation of middleware that are built to deliver all the functionality needed to implement distributed cloud applications in an enterprise setting. Cloud native application exploit microservice design partitioned into smaller unit services aligned with single responsibility pattern approach.  Those small units are packed into independent  deployable units which can be managed independently which especially valuable when appling best DevOps practices.
      </p>

	  <div class="slide1">
		<div>
		    <div class="col-accent"></div>
    		<div class="col-header-container-single">
      			<div class="col-header">Cloud Native Applications</div>
    		</div>
			<div class="slide-content">
				Essence of Cloud Native applictions:
				<ul>
					<li>Ability to rapidy deliver software that does not fail</li>
					<li>Ability are able to dynamically scale to deal with volumes of data previously unheard of</li>
					<li>Software that can handle massive load, remain responsicve and change as rapidly as requirements evolve</li>
				</ul>

				Cloud applications are designed with microservice paradigm in mind and managed with DevOps services for agile
				deployment and governance
			</div>
	  	</div>
		<div class="middle emphasis"><div class="arrows">&lt;&lt;</div><div>SUPPORT</div></div>

		<div class="slide-right">
			<div class="col-accent"></div>
    		<div class="col-header-container-single">
      			<div class="col-header">Cloud Native Platofrms</div>
    		</div>
			<div class="slide-content">
				<p>Cloud Native Platforms support distributed applications deployed over cloud infrastructure and provide rich platform services</p>
				<p>They deliver all the functionality needed to implement distributed applications in enterpise setting.</p>
				<p>Rich platform PaaS services are managed databases, application load balancing, serverless solutions, intelligent monitoring and tracing and DevOps framework.</p>

			</div>
	  	</div>
	  </div>

      <p>
        Cloud native platforms are built on the Container virtualisation technology foundations such as
        Docker and Kubernetes.  Kubernetes provide container orchestration, service appliance image
        Repositories and de-facto is standard used by many cloud providers. Properly designed cloud
        native solution integrated with Kubernetes can be deployed in cloud agnostic way in hybrid
        based deployments utilizing role-based access, logging, monitoring, auditing and security
        policies.
      </p>

	  <div class="slide2">
		<div>
		    <div class="col-accent"></div>
    		<div class="col-header-container-single">
      			<div class="col-header">to go native</div>
    		</div>
		</div>

		<img src="/assets/cloud_native_slide2.png" width="100%">
	  </div>


      <p>
        While getting started on Kubernetes is relatively easy, it requires a lot of knowledge to do it in a secure and cost-effective way.
        We follow latest guidelines to harden clusters security and adhere to (Center for Internet Security) CIS benchmarks both on
        Kubernetes and PaaS offerings from cloud providers (Google-GKE, AWS-EKS and Azure-AKS)
        There are also other frameworks such as Istio which is popular service mesh which can extend Kubernetes orchestration
        engine with dedicated microservices architecture like advanced load balancing, tracing, canary releases.
        We do have experience with using Google Anthos, Terraform, Cloudify which allow to get benefits from managed Kubernetes
        offerings for companies who (either temporarily or permanently) want to operate cloud native application in on-premise and
        public clouds, and also having opportunity to be cloud agnostic.
        Our employees are Certified Kubernetes Application Developer and Certified Kubernetes Administrator by Cloud Native Computing
        Foundation (CNCF).
      </p>
      <p>
        We can support our customers in migration to new cloud native architectures based on container technologies.
        Together we can analyze monolith solutions and define what are the required steps and design approaches to re-design and apply
        new microservice for frontend and backend tiers. Beside ensuring that the solution is fully functional, we know and are aware that security,
        performance and HA are also key factors to consider when such complex projects need to be realized.
      </p>
    </div>
  </div>
</div>
